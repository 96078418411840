<template>
	<div class="errpage">
		<h1>Got Lost?</h1>
		<NuxtLink to="/" class="btn">Back to homepage</NuxtLink>

		<!-- div v-if="!$isProd">
			<h1>Dev Only Error Log:</h1>
			<p>Error Code {{ error.statusCode }} </p>
			<p>{{ error.message }}</p>
		</div -->
	</div>
</template>

<script>
export default {
	props: ['error'],
}
</script>


<style scoped lang="scss">
.errpage {
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: flex;
	background: #0A0A0A;

	svg {
		max-width: 150px;
		height: auto;
		width: 80%;
	}

	h1 {
		margin: 20px 0 30px;
		font-size: 30px;
		max-width: 300px;
		text-align: center;
	}

	img {
		width: 500px;
		height: auto;
		position: absolute;
		left: calc(50% - 250px);
		pointer-events: none;
		opacity: 0.6;
		animation: blur 2s ease 0s infinite;
		animation-direction: alternate;
	}
}

@keyframes blur {

	0%,
	100% {
		-webkit-filter: blur(10px);
		-moz-filter: blur(10px);
		-o-filter: blur(10px);
		-ms-filter: blur(10px);
	}

	50% {
		-webkit-filter: blur(20px);
		-moz-filter: blur(20px);
		-o-filter: blur(20px);
		-ms-filter: blur(20px);
	}
}



@media (max-width: 750px) {
	.errpage {
		img {
			display: none;
		}
	}
}
</style>
